<template>
    <el-card class="box-card">
        <div slot="header" class="clearfix dashboard-card-title">
            <svg-icon icon-class="coins" class="dashboard-icon-card" />
            <span class="head-title">{{ $t('dashboard.right.billing-header') }}</span>
        </div>

        <div class="card-body">
            {{ $t('dashboard.right.billing-description') }}
            <p>
                <router-link :to="{ name: 'Facturare' }">
                    <el-button plain class="fullWidth" type="primary">{{ $t('general.details') }}</el-button>
                </router-link>
            </p>
        </div>
    </el-card>
</template>

<script>
export default {
    name: 'BillingCard'
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.dashboard-icon-card {
    font-size: 27px !important;
    vertical-align: middle;
    color: #ff601d;
}

.head-title {
    vertical-align: middle;
    font-weight: 500;
    margin-left: 3px;
}

.card-body {
    font-size: 13px;
}

a {
    color: #ff601d;
}
</style>
